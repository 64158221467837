import React, { useState } from "react";
import "./App.css";
import logo from "../src/Images/passmykeylogo.png";
import right from "../src/Images/arrow-right.png";
import property from "../src/Images/newone.png";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LandingPage = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
  });

  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
  });

  const validateField = (name, value) => {
    let error = "";

    if (name === "fullName") {
      if (!value.trim()) {
        error = "Full Name is required.";
      } else if (!/^[a-zA-Z\s]+$/.test(value)) {
        error = "Full Name should only contain letters and spaces.";
      } else if (value.length > 50) {
        error = "Full Name cannot exceed 50 characters.";
      }
    }

    if (name === "email") {
      if (!value.trim()) {
        error = "Email Address is required.";
      } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)) {
        error = "Enter a valid Email Address.";
      } else if (value.length > 100) {
        error = "Email cannot exceed 100 characters.";
      }
    }

    return error;
  };

  const validate = () => {
    let isValid = true;
    const newErrors = {};

    Object.keys(formData).forEach((field) => {
      const error = validateField(field, formData[field]);
      if (error) {
        newErrors[field] = error;
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: validateField(name, value),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      try {
        const response = await axios.post("https://api.passmykey.com/api/email/subscribe", {
          name: formData.fullName,
          email: formData.email,
        });
        console.log(response.data);
        toast.success(response.data.message || "Successfully subscribed!");
      } catch (error) {
        console.error("There was a problem with the Axios operation:", error);
        toast.error("There was an error with the subscription.");
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <header className="custom-header">
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container-fluid">
            <a className="navbar-brand" href="#">
              <img src={logo} alt="Logo" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </nav>
      </header>

      <section className="banner-section">
        <div className="container">
          <div className="row align-self">
            <div className="col-lg-6">
              <div className="left-banner">
                <p className="banner-p-1">
                  Our new site is <span>Launching Soon...</span>
                </p>
                <p className="banner-p-2">Interested in finding out how our revolutionary key exchange service can transform your business? Subscribe to our email newsletter below to find out more.</p>
                <form onSubmit={handleSubmit} className="input-section">
                  <div className="mar-15">
                    <input
                      type="text"
                      name="fullName"
                      className={`pass-inputs form-control ${errors.fullName ? "is-invalid" : ""}`}
                      placeholder="Enter Your Full Name"
                      value={formData.fullName}
                      onChange={handleInputChange}
                    />
                    {errors.fullName && <div className="invalid-feedback">{errors.fullName}</div>}
                  </div>
                  <div className="mar-15">
                    <input
                      type="text"
                      name="email"
                      className={`pass-inputs form-control ${errors.email ? "is-invalid" : ""}`}
                      placeholder="Enter Your Email Address"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                  </div>
                  <button type="submit" className="subscribe">
                    Subscribe <img src={right} alt="Arrow" />
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="right-banner">
                <img src={property} className="about-img img-responsive" alt="About" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LandingPage;
